<template>
    <div class="latch-1000">
        <ModalComponent v-model:visible="isModalVisible">
            <template #modal-title>
                {{ $t(`forms.${this?.formAction}`) }} - {{ $t('forms.FormAccountGroups') }}
            </template>

            <template #close>
                <CButton @click="hideModal" type="button" aria-label="Close" class="close">×</CButton>
            </template>

            <template #modal-body>
                <div class="latch-2000">
                    <FormAccountGroups ref="FormAccountGroups" @operation="this.handleFormOperationVerb"
                        :odataEndpoint="this.odataEndpoint" @close-modal="hideModal" @refreshTable="this.emitRefresh">
                    </FormAccountGroups>
                </div>
            </template>

            <template #modal-footer>
                <CButton v-if="this?.formAction !== 'view'" color="success" @click="resolveSubmission">{{
            $t('form_action.save') }}</CButton>
                <CButton color="secondary" @click="hideModal">{{ $t('form_action.close') }}</CButton>
            </template>
        </ModalComponent>
    </div>
</template>

<script>
import ModalSystemsMixin from '@/mixins/ModalSystemsMixin';

export default {
    name: 'ModalAccountGroups',
    mixins: [ModalSystemsMixin],
    data() {
        return {
            refComponent: 'FormAccountGroups',
        };
    },
};
</script>
