<template>
    <CRow>
        <CCol :xs="12">
            <CRow>
                <CCol :xs="12">
                    <p class="text-medium-emphasis">{{ $t('country') }}</p>
                    <div class="mb-4">
                        <v-select @search="this.debouncedCountryCode" :searchable="true" class="w-100 limitHeight"
                            v-model="this.outputCountry" :options="this.dropdownData.country"
                            :class="{ 'is-invalid': this.errors?.country }"
                            @option:selected="optionSelectedCountryJP" :placeholder="$t('country')" classes="w-100">
                            <template #no-options>{{ $t('no_data') }}</template>
                        </v-select>
                        <div class="invalid-feedback">{{ this.errors?.country }}</div>
                    </div>
                </CCol>
            </CRow>

            <!-- For addresses inside of Japan -->
            <div v-if="this.outputCountry === 'JP'">
                <CRow>
                    <CCol :xs="12" :md="6">
                        <p class="text-medium-emphasis">{{ $t('zip_jp') }}</p>
                        <CInputGroup class="mb-4">
                            <v-select class="w-100 limitHeight" v-model="this.addressData.jp.zip_code"
                                @search="this.debouncedPostalCode" @option:selected="optionSelectedPostalCodeJP"
                                :show-labels="true" :placeholder="$t('zip_jp')" :searchable="true" classes="w-100"
                                :class="{ 'is-invalid': this.errors?.zip_code }"
                                :options="this.dropdownData.zip_code">
                                <template #no-options>{{ $t('no_data') }}</template>
                            </v-select>
                            <div class="invalid-feedback">{{ this.errors?.zip_code }}</div>
                        </CInputGroup>
                    </CCol>
                    <CCol :xs="12" :md="6">
                        <p class="text-medium-emphasis">{{ $t('prefecture_jp') }}</p>
                        <CInputGroup class="mb-4">
                            <v-select class="w-100 limitHeight" v-model="this.addressData.jp.state"
                                :disabled="this.modelAddressCompleted >= 1" @option:selected="optionSelectedPrefectureJP"
                                :show-labels="true" :placeholder="$t('prefecture_jp')" :searchable="true" classes="w-100"
                                :class="{ 'is-invalid': this.errors?.state }"
                                :options="this.dropdownData.state">
                                <template #no-options>{{ $t('no_data') }}</template>
                            </v-select>
                            <div class="invalid-feedback">{{ this.errors?.state }}</div>
                        </CInputGroup>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol :xs="12" :md="12">
                        <p class="text-medium-emphasis">{{ $t('city_jp') }}</p>
                        <CInputGroup class="mb-4">
                            <v-select class="w-100 limitHeight" v-model="this.addressData.jp.city"
                                :disabled="this.modelAddressCompleted >= 2" @option:selected="onSelectedCityJP"
                                :show-labels="true" :placeholder="$t('city_jp')" :searchable="true" classes="w-100"
                                :class="{ 'is-invalid': this.errors?.city }"
                                :options="this.dropdownData.city">
                                <template #no-options>{{ $t('no_data') }}</template>
                            </v-select>
                            <div class="invalid-feedback">{{ this.errors?.city }}</div>
                        </CInputGroup>
                    </CCol>
                    <CCol :xs="12" :md="12">
                        <p class="text-medium-emphasis">{{ $t('street2_jp') }}</p>
                        <CInputGroup class="mb-4">
                            <v-select class="w-100 limitHeight" v-model="this.addressData.jp.street2"
                                :disabled="this.modelAddressCompleted === 3" :show-labels="true"
                                @option:selected="onSelectedAreaJP" :placeholder="$t('street2_jp')" :searchable="true"
                                :class="{ 'is-invalid': this.errors?.street2 }"
                                classes="w-100" :options="this.dropdownData.street2">
                                <template #no-options>{{ $t('no_data') }}</template>
                            </v-select>
                            <div class="invalid-feedback">{{ this.errors?.street2 }}</div>
                        </CInputGroup>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol :xs="12">
                        <p class="text-medium-emphasis">{{ $t('street1_jp') }}</p>
                        <CInputGroup class="mb-4">
                            <text-field :key="this.index" :class="{ 'is-invalid': this.errors?.street1 }"
                                v-model="this.addressData.jp.street1" :db="{
                                    model: 'address',
                                    field: 'street1',
                            }"></text-field>
                            <div class="invalid-feedback">{{ this.errors?.street1 }}</div>
                        </CInputGroup>
                    </CCol>
                </CRow>
            </div>

            <!-- For addresses outside of Japan -->
            <div v-if="this.outputCountry !== 'JP'">
                <CRow>
                    <CCol :xs="12">
                        <p class="text-medium-emphasis">{{ $t('street1_uk') }}</p>
                        <CInputGroup class="mb-4">
                            <text-field :key="this.index" :class="{ 'is-invalid': this.errors?.street1 }"
                                v-model="this.addressData.en.street1" :db="{
                                    model: 'address',
                                    field: 'street1',
                                }"></text-field>
                            <div class="invalid-feedback">{{ this.errors?.street1 }}</div>
                        </CInputGroup>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol :xs="12">
                        <p class="text-medium-emphasis">{{ $t('street2_uk') }}</p>
                        <CInputGroup class="mb-4">
                            <text-field :key="this.index" v-model="this.addressData.en.street2"
                                :class="{ 'is-invalid': this.errors?.street2 }" :db="{
                                    model: 'address',
                                    field: 'street2',
                                }"></text-field>
                            <div class="invalid-feedback">{{ this.errors?.street2 }}</div>
                        </CInputGroup>
                    </CCol>
                    <CCol :xs="12">
                        <p class="text-medium-emphasis">{{ $t('city_uk') }}</p>
                        <CInputGroup class="mb-4">
                            <v-select class="w-100 limitHeight" v-model="this.addressData.en.city"
                                :disabled="!(this.modelAddressCompleted >= 1)" @option:selected="onSelectedCityEN"
                                :show-labels="true" :placeholder="$t('city_uk')" :searchable="true" classes="w-100"
                                :class="{ 'is-invalid': this.errors?.city }" :options="this.dropdownData.city"
                                @search="this.debouncedCityEN">
                                <template #no-options>{{ $t('no_data') }}</template>
                            </v-select>
                            <div class="invalid-feedback">{{ this.errors?.city }}</div>
                        </CInputGroup>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol :xs="12" :md="6">
                        <p class="text-medium-emphasis">{{ $t('prefecture_uk') }}</p>
                        <CInputGroup class="mb-4">
                            <CInputGroup class="mb-4">
                                <v-select class="w-100 limitHeight" v-model="this.addressData.en.state"
                                    :disabled="2 >= this.modelAddressCompleted && this.modelAddressCompleted < 5"
                                    :searchable="true" classes="w-100" :show-labels="true"
                                    :class="{ 'is-invalid': this.errors?.state }" :placeholder="$t('prefecture_uk')"
                                    :options="this.dropdownData.state">
                                    <template #no-options>{{ $t('no_data') }}</template>
                                </v-select>
                                <div class="invalid-feedback">{{ this.errors?.state }}</div>
                            </CInputGroup>
                        </CInputGroup>
                    </CCol>
                    <CCol :xs="12" :md="6">
                        <p class="text-medium-emphasis">{{ $t('zip_uk') }}</p>
                        <CInputGroup class="mb-4">
                            <text-field :key="this.index"
                            :class="{ 'is-invalid': this.errors?.zip_code }"
                            v-model="this.addressData.en.zip_code" :db="{
                                model: 'address',
                                field: 'zip_code',
                            }"></text-field>
                            <div class="invalid-feedback">{{ this.errors?.zip_code }}</div>
                        </CInputGroup>
                    </CCol>
                </CRow>
            </div>
        </CCol>
    </CRow>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import InputAutoComplete from '@/fields/InputAutoComplete.vue';
import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
import { useStore } from 'vuex';

 /* eslint-disable no-unused-vars*/
export default {
    name: 'AddressModal',
    components: {
        'text-field': InputAutoComplete
    },
    mixins: [ValidationErrorMixin, ValidationSuccessMixin],
    props: {
        modelValue: {
            type: Object,
        },
        noWatch: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: '',
            index: 0,
            modelAddressCompleted: 0,
            dropdownData: {
                country: [],
                city: [],
                zip_code: [],
                street2: [],
                state: [],
            },
            errors: {
                'country.code': null,
                'city.code': null,
                'state.code': null,
                street1: null,
                street2: null,
            },
            addressData: {
                jp: {
                    street1: null,
                    street2: null,
                    city: null,
                    state: null,
                    zip_code: null,
                    country: null
                },
                en: {
                    street1: null,
                    street2: null,
                    city: null,
                    state: null,
                    zip_code: null,
                    country: null
                }
            },
            debouncedCountryCode: this.$gh.debounce(this.searchCountryJP, 200),
            debouncedPostalCode: this.$gh.debounce(this.searchPostalCodeJP, 200),
            debouncedCityEN: this.$gh.debounce(this.searchCityEN, 200),
        }
    },
    setup() {
        const ctx = getCurrentInstance();
        ctx.appContext.config.globalProperties.$gh.boot();


        const store = useStore();
        return {
            loading: computed(() => store.state.core.loading),
            commit: store.commit,
            dispatch: store.dispatch,
        }
    },
    computed: {
        urlCountriesJP: function () {
            return `/settings/find-country?q=${this.search}`;
        },
        urlPostalCodeSingleJP: function () {
            return `/settings/code?q=${this.search}`;
        },
        urlAddressFetchJP: function () {
            return `/settings/find-jp?code=${this.addressData.jp?.zip_code?.code || ''}&prefecture=${this.addressData.jp?.state?.code || ''}&city=${this.addressData.jp?.city?.code || ''}&area=${this.addressData.jp?.street2?.code || ''}`;
        },
        urlCityStatesFetchEN: function () {
            return `/settings/find-city?q=${this.search}&country=${this.outputCountry}`;
        },
        outputCountry: {
            get: function () {
                if (!this.addressData.jp?.country?.code && !this.addressData.en?.country?.code) {
                    return '';
                } else {
                    return this.addressData.jp?.country?.code || this.addressData.en?.country?.code || '';
                }
            },
            set: function (value) {
                this.addressData.jp.country = value;
                this.addressData.en.country = value;
            }
        },
        outputCountryObject: function () {
            return this.addressData.jp?.country || this.addressData.en?.country || null;
        },
    },
    methods: {
        searchCountryJP: function (search, loading) {
            loading(true);
            this.commit('core/updateLoading', { value: true }, { root: true });
            this.search = search;
            this.$gh.post(this.urlCountriesJP).then((response) => {
                this.dropdownData.country = response.data.items;
            }).catch(e => null).finally(() => {
                loading(false);
                this.commit('core/updateLoading', { value: false }, { root: true });
            });
        },
        optionSelectedCountryJP: function () {
            this.search = '';
            this.index++;

            if (this.outputCountry !== 'JP') {
                this.modelAddressCompleted = 1;
                this.addressData.en.state = this.addressData.en.city = null;
                this.addressData.en.street1 = this.addressData.en.street2 = this.addressData.en.zip_code = null;
            } else {
                this.modelAddressCompleted = 3;
                this.addressData.jp.state = this.addressData.jp.city = this.addressData.jp.zip_code = null;
                this.addressData.jp.street1 = this.addressData.jp.street2 = null;
            }

            this.dropdownData.city = this.dropdownData.state = this.dropdownData.zip_code = this.dropdownData.street2 = [];
            this.$emit('update:isdCode', this.outputCountryObject);
        },
        searchPostalCodeJP: function (search, loading) {
            if (search.length < 4) {
                this.dropdownData.zip_code = [];
                return;
            }

            loading(true);
            this.addressData.jp.state = this.addressData.jp.city = this.addressData.jp.street2 = this.addressData.jp.zip_code = null;
            this.addressData.jp.street1 = null;

            this.modelAddressCompleted = 0;
            this.commit('core/updateLoading', { value: true }, { root: true });
            this.search = search;

            this.$gh.post(this.urlPostalCodeSingleJP).then((response) => {
                this.dropdownData.zip_code = response.data.items;
            }).catch(e => null).finally(() => {
                loading(false);
                this.commit('core/updateLoading', { value: false }, { root: true });
            });
        },
        searchCityEN: function (search, loading) {
            if (search.length < 2) {
                return;
            }

            loading(true);
            this.commit('core/updateLoading', { value: true }, { root: true });
            this.search = search;
            this.$gh.post(this.urlCityStatesFetchEN).then((response) => {
                this.dropdownData.city = response.data.items.city;
                this.dropdownData.state = response.data.items.state;
            }).catch(e => null).finally(() => {
                this.commit('core/updateLoading', { value: false }, { root: true });
                loading(false);
                this.search = '';
            });
        },
        onSelectedCityEN: function (option) {
            this.search = '';

            // Find the state of the selected city. City has code, state_code and label. State has code and label.
            const state = this.dropdownData.state.find((state) => state.code === option.state_code);
            if (state) {
                this.addressData.en.state = state;
                this.modelAddressCompleted = 2;
            } else {
                this.modelAddressCompleted = 3;
            }
        },
        optionSelectedPostalCodeJP: function () {
            this.commit('core/updateLoading', { value: true }, { root: true });
            this.addressData.jp.state = this.addressData.jp.city = this.addressData.jp.street2 = [];
            let completedInteractions = 0;
            this.search = '';

            this.$gh.post(this.urlAddressFetchJP).then((response) => {
                this.dropdownData.street2 = response.data.items.area;
                if (this.dropdownData.street2.length === 1) {
                    this.addressData.jp.street2 = this.dropdownData.street2[0];
                    ++completedInteractions;
                }

                this.dropdownData.city = response.data.items.city;
                if (this.dropdownData.city.length === 1) {
                    this.addressData.jp.city = this.dropdownData.city[0];
                    ++completedInteractions;
                }

                this.dropdownData.state = response.data.items.prefecture;
                if (this.dropdownData.state.length === 1) {
                    this.addressData.jp.state = this.dropdownData.state[0];
                    ++completedInteractions;
                }

                this.modelAddressCompleted = completedInteractions;
            }).catch(e => null).finally(() => {
                this.commit('core/updateLoading', { value: false }, { root: true });
            });
        },
        optionSelectedPrefectureJP: function () {
            this.search = '';
            this.modelAddressCompleted = 1;
            this.addressData.jp.city = this.addressData.jp.street2 = null;
            this.addressData.jp.street1 = null;
        },
        onSelectedCityJP: function () {
            this.search = '';
            this.modelAddressCompleted = 2;
            this.addressData.jp.street2 = null;
            this.addressData.jp.street1 = null;
        },
        onSelectedAreaJP: function () {
            this.search = '';
            this.modelAddressCompleted = 3;
            this.addressData.jp.street1 = null;
        },
        resetErrors: function () {
            this.errors = null;
        },
        boot() {
            this.addressData.en = {
                street1: null, street2: null, city: null, state: null, zip_code: null, country: null
            };
            this.addressData.jp = {
                street1: null, street2: null, city: null, state: null, zip_code: null, country: null
            };
            this.outputCountry = null;
            this.modelAddressCompleted = 0;
        },
        resolveSubmission: function (address) {
            this.$emit('update:address', address);
            this.resetErrors();
            this.index++;
            this.boot();
        },
        initalizeAddress() {
            if (this.modelValue?.country?.code) {
                if (this.modelValue.country.code === 'JP') {
                    this.addressData.jp = this.modelValue;
                    this.modelAddressCompleted = 3;
                } else {
                    this.addressData.en = this.modelValue;
                    this.modelAddressCompleted = 5;
                }
            } else {
                this.boot();
            }
        }
    },
    mounted: function () {
        this.initalizeAddress();
    },
    watch: {
        'addressData': {
            deep: true,
            handler: function (newValue) {
                if (this.noWatch) {
                    return;
                }

                // Check if country is japan or not.
                if (newValue.jp?.country?.code === 'JP') {
                    this.$emit('update:modelValue', this.addressData.jp);
                } else {
                    this.$emit('update:modelValue', this.addressData.en);
                }
            },
            immediate: true,
        },
        'modelValue': {
            deep: true,
            handler: function (newValue) {
                if (this.noWatch) {
                    return;
                }
                if ( newValue?.jp ) {
                    this.addressData.jp = newValue.jp;
                }

                if ( newValue?.en ) {
                    this.addressData.en = newValue.en;
                }
            },
            immediate: true,
        },
    },
    i18n: {
        messages: {
            en: {
                country: 'Country',

                // For Western
                street1_uk: 'Address Line 1',
                street2_uk: 'Address Line 2',
                city_uk: 'City/District/Town',
                prefecture_uk: 'State/Zone/Province',
                zip_uk: 'Zip Code/Postal Code',

                // Japanese
                street1_jp: 'Area/House/Apartment/Room Name and Number',
                street2_jp: 'Town/Village',
                city_jp: 'City/District',
                prefecture_jp: 'Prefecture',
                zip_jp: 'Postal Code',

                // Data
                no_data: 'Please enter information here',
                JP: '日本',
            },
            np: {
                country: 'देश',

                // For Western
                street1_uk: 'सड़क ठेगाना १',
                street2_uk: 'सड़क ठेगाना २',
                city_uk: 'सहर/नगरपालिका/गाउपालिका',
                prefecture_uk: 'राज्य/प्रदेश/प्रान्त',
                zip_uk: 'जिप/पोस्टल कोड',

                // For Japanese
                street1_jp: 'घर/अपार्टमेन्ट/कोठा नम्बर',
                street2_jp: 'क्षेत्र',
                city_jp: 'शहर/नगर',
                prefecture_jp: 'प्रदेश',
                zip_jp: 'जिप कोड/पोस्टल कोड',

                // Data
                no_data: 'यहाँ डाटा राख्नुहोस्',
                JP: '日本',
            },
            jp: {
                country: '国または国コード',

                // For Western
                street1_uk: '住所1',
                street2_uk: '住所2',
                city_uk: '市区町村',
                prefecture_uk: '都道府県',
                zip_uk: '郵便番号',

                // For Japanese
                street1_jp: '番地・建物名',
                street2_jp: '町名',
                city_jp: '市区町村',
                prefecture_jp: '都道府県',
                zip_jp: '郵便番号',

                // Data
                no_data: 'ここに情報を入力してください',
                JP: '日本',
            },
        }
    },
}
</script>
