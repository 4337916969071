<template>
    <div :key="this.refIndexKey">
        {{ JSON.stringify(formData) }}
        <CNav variant="tabs" role="tablist">
            <CNavItem>
                <CNavLink href="javascript:void(0);" :active="tabPaneActiveKey === 1" :disabled="this.tabsDisabled">
                    {{ $t('shipper') }}
                </CNavLink>
            </CNavItem>
            <CNavItem>
                <CNavLink href="javascript:void(0);" :active="tabPaneActiveKey === 2" :disabled="this.tabsDisabled">
                    {{ $t('consignee') }}
                </CNavLink>
            </CNavItem>
            <CNavItem>
                <CNavLink href="javascript:void(0);" :active="tabPaneActiveKey === 3" :disabled="this.tabsDisabled">
                    {{ $t('bill_to') }}
                </CNavLink>
            </CNavItem>
            <CNavItem>
                <CNavLink href="javascript:void(0);" :active="tabPaneActiveKey === 4" :disabled="this.tabsDisabled">
                    {{ $t('ship_to') }}
                </CNavLink>
            </CNavItem>
            <CNavItem>
                <CNavLink href="javascript:void(0);" :active="tabPaneActiveKey === 5" :disabled="this.tabsDisabled">
                    {{ $t('cargo') }}
                </CNavLink>
            </CNavItem>
        </CNav>

        <CTabContent class="fade-in">
            <CTabPane class="fade-in" role="tabpanel" aria-labelledby="shipper-tab" :visible="tabPaneActiveKey === 1">
                <CRow class="justify-content m-2">
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.shipper_name') }}</CFormLabel>
                        <text-field v-model="formData.shipper"
                            :db="{ model: 'organizations', field: 'name' }"></text-field>
                    </CCol>
                    <CCol :xs="12" :md="12" :lg="12">
                        <address-modal v-model="this.formData.shipper_address"
                            @update:isdCode="(value) => this.$refs.shipperPhone.selectByCountry(value)"></address-modal>
                    </CCol>
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <phone-number ref="shipperPhone" v-model="this.formData.shipper_telephone"></phone-number>
                    </CCol>
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <div class="d-flex justify-content-end">
                            <CButton v-if="this.tabPaneActiveKey > 1" color="secondary"
                                @click="this.tabPaneActiveKey--">{{ $t('previous') }}</CButton>
                            &nbsp;
                            <CButton v-if="this.tabPaneActiveKey != this.tabPaneMax" class="ml-2" color="success"
                                @click="this.tabPaneActiveKey++">{{ $t('next') }}</CButton>
                        </div>
                    </CCol>
                </CRow>
            </CTabPane>
            <CTabPane class="fade-in" role="tabpanel" aria-labelledby="consignee-tab" :visible="tabPaneActiveKey === 2">
                <CRow class="justify-content m-2">
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.consignee_name') }}</CFormLabel>
                        <text-field v-model="formData.consignee"
                            :db="{ model: 'organizations', field: 'name' }"></text-field>
                    </CCol>
                    <CCol :xs="12" :md="12" :lg="12">
                        <address-modal v-model="this.formData.consignee_address"
                            @update:isdCode="(value) => this.$refs.consigneePhone.selectByCountry(value)"></address-modal>
                    </CCol>
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <phone-number ref="consigneePhone" v-model="this.formData.consignee_telephone"></phone-number>
                    </CCol>
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <div class="d-flex justify-content-end">
                            <CButton v-if="this.tabPaneActiveKey > 1" color="secondary"
                                @click="this.tabPaneActiveKey--">{{ $t('previous') }}</CButton>
                            &nbsp;
                            <CButton v-if="this.tabPaneActiveKey != this.tabPaneMax" class="ml-2" color="success"
                                @click="this.tabPaneActiveKey++">{{ $t('next') }}</CButton>
                        </div>
                    </CCol>
                </CRow>
            </CTabPane>
            <CTabPane class="fade-in" role="tabpanel" aria-labelledby="bill_to-tab" :visible="tabPaneActiveKey === 3">
                <CRow class="justify-content m-2">
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.bill_to_party') }}</CFormLabel><br>
                        <CFormCheck type="radio" id="bill_to_party1" inline :label="this.shipper" value="shipper"
                            v-model="formData.bill_to_party" />
                        <CFormCheck type="radio" id="bill_to_party2" inline :label="this.consignee"
                            value="consignee" v-model="formData.bill_to_party" />
                        <CFormCheck type="radio" id="bill_to_party3" inline :label="this.other" value="other"
                            v-model="formData.bill_to_party" />
                    </CCol>
                    <CCol v-if="formData.bill_to_party == 'other'" class="mb-4" :xs="12" :md="12" :lg="12">
                        <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                            <CFormLabel>{{ $t('formData.bill_to_party') }}</CFormLabel>
                            <text-field v-model="formData.bill_to_party"
                                :db="{ model: 'organizations', field: 'name' }"></text-field>
                        </CCol>
                        <CCol :xs="12" :md="12" :lg="12">
                            <address-modal v-model="this.formData.shipper_address"
                                @update:isdCode="(value) => this.$refs.shipperPhone.selectByCountry(value)"></address-modal>
                        </CCol>
                        <CCol :xs="12" :md="12" :lg="12">
                            <CFormLabel>{{ $t('formData.bill_to_party_contact') }}</CFormLabel>
                            <CFormInput :placeholder="$t('formData.bill_to_party_contact')"
                                :aria-label="$t('formData.bill_to_party_contact')"
                                v-model="formData.bill_to_party_contact" />
                        </CCol>
                        <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                            <phone-number ref="shipperPhone" v-model="this.formData.bill_to_party_phone"></phone-number>
                        </CCol>
                    </CCol>
                    <CCol class="mb-2 mt-2" :xs="12" :md="12" :lg="12">
                        <div class="d-flex justify-content-end">
                            <CButton v-if="this.tabPaneActiveKey > 1" color="secondary"
                                @click="this.tabPaneActiveKey--">{{ $t('previous') }}</CButton>
                            &nbsp;
                            <CButton v-if="this.tabPaneActiveKey !== this.tabPaneMax" class="ml-2" color="success"
                                @click="this.tabPaneActiveKey++">
                                {{ $t('next') }}</CButton>
                        </div>
                    </CCol>
                </CRow>
            </CTabPane>
            <CTabPane class="fade-in" role="tabpanel" aria-labelledby="ship_to-tab" :visible="tabPaneActiveKey === 4">
                <CRow class="justify-content m-2">
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.ship_to_party') }}</CFormLabel><br>
                        <CFormCheck type="radio" id="ship_to_party1" inline :label="this.consignee" value="consignee"
                            v-model="formData.ship_to_party" />
                        <CFormCheck type="radio" id="ship_to_party2" inline :label="this.other" value="other"
                            v-model="formData.ship_to_party" />
                    </CCol>
                    <CCol v-if="formData.ship_to_party == 'other'" class="mb-4" :xs="12" :md="12" :lg="12">
                        <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                            <CFormLabel>{{ $t('formData.ship_to_party_name') }}</CFormLabel>
                            <text-field v-model="formData.ship_to_party_name"
                                :db="{ model: 'organizations', field: 'name' }"></text-field>
                        </CCol>
                        <CCol :xs="12" :md="12" :lg="12">
                            <address-modal v-model="this.formData.ship_to_party_address"
                                @update:isdCode="(value) => this.$refs.ship_to_party_phone.selectByCountry(value)"></address-modal>
                        </CCol>
                        <CCol :xs="12" :md="12" :lg="12">
                            <CFormLabel>{{ $t('formData.ship_to_party_contact') }}</CFormLabel>
                            <CFormInput :placeholder="$t('formData.ship_to_party_contact')"
                                :aria-label="$t('formData.ship_to_party_contact')"
                                v-model="formData.ship_to_party_contact" />
                        </CCol>
                        <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                            <phone-number ref="ship_to_party_phone" v-model="this.formData.ship_to_party_phone"></phone-number>
                        </CCol>
                    </CCol>

                    <CCol class="mb-2 mt-2" :xs="12" :md="12" :lg="12">
                        <div class="d-flex justify-content-end">
                            <CButton v-if="this.tabPaneActiveKey > 1" color="secondary"
                                @click="this.tabPaneActiveKey--">{{ $t('previous') }}</CButton>
                            &nbsp;
                            <CButton v-if="this.tabPaneActiveKey !== this.tabPaneMax" class="ml-2" color="success"
                                @click="this.tabPaneActiveKey++">{{ $t('next') }}
                            </CButton>
                        </div>
                    </CCol>
                </CRow>

            </CTabPane>
            <CTabPane class="fade-in" role="tabpanel" aria-labelledby="cargo" :visible="tabPaneActiveKey === 5">
                <CRow class="justify-content m-2">
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <hs-code v-model="formData.hs_code"></hs-code>
                    </CCol>
                    <CCol class="mt-2 mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.description') }}</CFormLabel>
                        <CFormTextarea :disabled="this.formData.hs_code === null"
                            :placeholder="$t('formData.description')" :aria-label="$t('formData.description')" rows="5"
                            v-model="formData.description" />
                    </CCol>
                    <CCol class="mt-2 mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.remarks') }}</CFormLabel>
                        <CFormTextarea :disabled="this.formData.hs_code === null" :placeholder="$t('formData.remarks')"
                            :aria-label="$t('formData.remarks')" rows="5" v-model="formData.remarks" />
                    </CCol>
                    <CCol class="mb-4" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.declared_value_for_customs') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.declared_value_for_customs')"
                            :aria-label="$t('formData.declared_value_for_customs')" v-model="formData.declared_value" />
                    </CCol>
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.service_type') }}</CFormLabel><br>
                        <CFormCheck type="radio" id="service_type1" inline label="DOX" value="dox"
                            v-model="formData.service_type" />
                        <CFormCheck type="radio" id="service_type2" inline label="SPX" value="spx"
                            v-model="formData.service_type" />
                        <CFormCheck type="radio" id="service_type3" inline :label="this.other" value="other"
                            v-model="formData.service_type" />
                    </CCol>
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('commercial') }}</CFormLabel><br>
                        <CFormCheck type="radio" id="commercial1" inline value="yes" :label="this.commercial"
                            v-model="formData.commercial" />
                        <CFormCheck type="radio" id="commercial2" inline value="no" :label="this.nonCommercial"
                            v-model="formData.commercial" />
                    </CCol>
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.insurance_required') }}</CFormLabel><br>
                        <CFormCheck id="insurance" value="yes" v-model="formData.insurance" />
                    </CCol>
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.tax_duty_paid') }}</CFormLabel><br>
                        <CFormCheck id="tax_duty" value="yes" v-model="formData.tax_duty" />
                    </CCol>
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <div v-if="formData.tax_duty == 'yes'">
                            <CFormLabel>{{ $t('formData.tax_duty_responsible_party') }}</CFormLabel><br>
                            <CFormCheck type="radio" id="tax_duty_party1" inline :label="this.shipper" value="shipper"
                                v-model="formData.tax_duty_party" />
                            <CFormCheck type="radio" id="tax_duty_party2" inline :label="this.consignee"
                                value="consignee" v-model="formData.tax_duty_party" />
                        </div>
                    </CCol>
                    <CCol v-if="formData.insurance == 'yes'" class="mb-2" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.insurance_value') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.insurance_value')"
                            :aria-label="$t('formData.insurance_value')" v-model="formData.insurance_value" />
                    </CCol>
                    <CCol class="mb-4" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.freight_charge') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.freight_charge')"
                            :aria-label="$t('formData.freight_charge')" v-model="formData.freight_charge" />
                    </CCol>
                    <CCol class="mb-2" :xs="4" :md="4" :lg="4">
                        <CFormLabel>{{ $t('formData.other_charge') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.other_charge')" :aria-label="$t('formData.other_charge')"
                            v-model="formData.other_charge" />
                    </CCol>
                    <CCol class="mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.total_charge') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.total')" :aria-label="$t('formData.total')" disabled
                            v-model="totalComputed" />
                    </CCol>
                    <CCol class="mb-2" :xs="6" :md="6" :lg="6">
                        <CFormLabel>{{ $t('formData.destination_city') }}</CFormLabel>
                        <text-field v-model="formData.destination_city"
                            :db="{ model: 'city', field: 'name' }"></text-field>
                    </CCol>
                    <CCol class="mb-2" :xs="6" :md="6" :lg="6">
                        <CFormLabel>{{ $t('formData.no_of_packages') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.no_of_packages')"
                            :aria-label="$t('formData.no_of_packages')" v-model="formData.no_of_packages" />
                    </CCol>
                    <CCol class="mt-2 mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.dimensions') }}</CFormLabel>
                        <CFormTextarea :placeholder="`l1xb1xh1=w1\nl2xb2xh2=w2\n...`"
                            @change="() => { formData.volumetric_weight = calculateRoundedResult() }"
                            :aria-label="`l1xb1xh1=w1\nl2xb2xh2=w2\n...`" rows="6" v-model="formData.dimensions" />
                    </CCol>
                    <CCol class="mb-2" :xs="6" :md="6" :lg="6">
                        <CFormLabel>{{ $t('formData.actual_weight') }}</CFormLabel>
                        <CFormInput :placeholder="$t('formData.actual_weight')"
                            :aria-label="$t('formData.actual_weight')" v-model="formData.actual_weight" />
                    </CCol>
                    <CCol class="mb-2" :xs="6" :md="6" :lg="6">
                        <CFormLabel>{{ $t('formData.volumetric_weight') }}</CFormLabel>
                        <CFormInput :aria-label="$t('formData.volumetric_weight')" disabled
                            v-model="formData.volumetric_weight" />
                    </CCol>
                    <hr class="mt-4 mb-4">
                    <CCol class="mb-2" :xs="6" :md="6" :lg="6">
                        <CFormLabel>{{ $t('formData.pickup_date') }}</CFormLabel>
                        <CFormInput type="date" :aria-label="$t('formData.pickup_date')"
                            v-model="formData.pickup_date" />
                    </CCol>
                    <CCol class="mb-2" :xs="6" :md="6" :lg="6">
                        <CFormLabel>{{ $t('formData.pickup_by') }}</CFormLabel>
                        <text-field v-model="formData.pickup_by" :db="{ model: 'user', field: 'name' }"></text-field>
                    </CCol>
                    <CCol class="mt-2 mb-2" :xs="12" :md="12" :lg="12">
                        <CFormLabel>{{ $t('formData.agent') }}</CFormLabel>
                        <text-field v-model="formData.agent"
                            :db="{ model: 'organizations', field: 'name' }"></text-field>
                    </CCol>
                    <CCol class="mb-2 mt-2" :xs="12" :md="12" :lg="12">
                        <div class="d-flex justify-content-end">
                            <CButton v-if="this.tabPaneActiveKey > 1" color="secondary"
                                @click="this.tabPaneActiveKey--">{{ $t('previous') }}</CButton>
                            &nbsp;
                            <CButton v-if="this.tabPaneActiveKey !== this.tabPaneMax" class="ml-2" color="success"
                                @click="this.tabPaneActiveKey++">{{ $t('next') }}
                            </CButton>
                        </div>
                    </CCol>
                </CRow>
            </CTabPane>
        </CTabContent>

        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('id') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('id')"
                    :aria-label="$t('id')" :class="{ 'is-invalid': this?.errors?.id }" :aria-describedby="$t('id')"
                    v-model="formData.id" />
                <div class="invalid-feedback">{{ this?.errors?.id }}</div>
            </CCol>
        </CRow>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('created_at') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('created_at')" :disabled="this.disabledAttribute"
                    :placeholder="$t('created_at')" :aria-label="$t('created_at')"
                    :class="{ 'is-invalid': this?.errors?.created_at }" :aria-describedby="$t('created_at')"
                    :value="this.getLocaleDate(formData.created_at)" />
                <div class="invalid-feedback">{{ this?.errors?.created_at }}</div>
            </CCol>
        </CRow>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('updated_at') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('updated_at')" :disabled="this.disabledAttribute"
                    :placeholder="$t('updated_at')" :aria-label="$t('updated_at')"
                    :class="{ 'is-invalid': this?.errors?.updated_at }" :aria-describedby="$t('updated_at')"
                    :value="this.getLocaleDate(formData.updated_at)" />
                <div class="invalid-feedback">{{ this?.errors?.updated_at }}</div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
import FormSystemsMixin from '@/mixins/FormSystemsMixin';
import ValidationSchema from '@/schemas/BookingsSchema';
import InputAutoComplete from '@/fields/InputAutoComplete.vue';
import PhoneNumber from '@/fields/PhoneNumber.vue';
import AddressModal from '@/fields/AddressModal.vue';
import HSCode from '@/fields/HSCode.vue';

export default {
    name: 'FormBookings',
    components: {
        'text-field': InputAutoComplete,
        'phone-number': PhoneNumber,
        'address-modal': AddressModal,
        'hs-code': HSCode,
    },
    mixins: [ValidationErrorMixin, ValidationSuccessMixin, FormSystemsMixin],
    data() {
        return {
            dataURL: 'api/v1/bookings',
            ValidationSchema: ValidationSchema,
            formData: {
                id: '',
            },
            errors: {
                id: null,
            },
            test1: '',
            tabPaneActiveKey: 1,
            tabPaneMax: 5,
            dataAddress: null,
            dataPhone: {
                intlCode: null,
                phone: null,
                extension: null,
            },
        }
    },
    computed: {
        description: function () {
            return `${this.formData.description}${this.formData.hs_code?.hsc ? `[${this.formData.hs_code?.hsc}] ` : ''} ${this.formData.hs_code?.top_parent ? `${this.formData.hs_code?.top_parent}\n` : ''}${this.formData.hs_code?.label || ''}`;
        },
        other: function () {
            return this.$t('other');
        },
        commercial: function () {
            return this.$t('commercial');
        },
        nonCommercial: function () {
            return this.$t('nonCommercial');
        },
        shipper: function () {
            return this.$t('shipper');
        },
        consignee: function () {
            return this.$t('consignee');
        },
        totalComputed: function () {
            return parseFloat(this.formData.freight_charge) + parseFloat(this.formData.other_charge) + parseFloat(this.formData.insurance_value);
        },
    },
    methods: {
        calculateRoundedResult: function () {
            try {
                let input = this.formData.dimensions.replace(/,\s?/g, "\n");

                // Split into lines
                let lines = input.split("\n");

                let totalVolume = 0;

                lines.forEach(line => {
                    // Split each line into dimensions and value
                    let [dims, value] = line.split("=");

                    // Split dimensions into individual values (case insensitive for "x" or "X")
                    let dimensions = dims.split(/x/i).map(Number);

                    // Calculate the volume
                    let volume = dimensions[0] * dimensions[1] * dimensions[2] * parseInt(value);

                    // Add to total volume
                    totalVolume += volume;
                });

                // Calculate the final result
                let result = totalVolume / 6000;

                // Round the result to 2 decimal places
                let roundedResult = Math.round(result * 100) / 100;

                return roundedResult.toFixed(2);
            } catch (e) {
                console.log(e);
                return '!WAITING';
            }
        },
        resolveSubmission: function () {
            this.resetErrors();
            this.refIndexKey++;
            this.boot();
        }
    },
    i18n: {
        messages: {
            en: {
            },
            np: {
            },
            jp: {
            },
        },
    },
}
</script>
