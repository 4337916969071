<template>
    <div :key="this.refIndexKey">
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('id') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :placeholder="$t('id')"
                    :aria-label="$t('id')" :class="{ 'is-invalid': this?.errors?.id }" :aria-describedby="$t('id')"
                    v-model="formData.id" />
                <div class="invalid-feedback">{{ this?.errors?.id }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('name') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('name')" :disabled="this.disabledAttribute"
                    :placeholder="$t('name')" :aria-label="$t('name')" :class="{ 'is-invalid': this?.errors?.name }"
                    :aria-describedby="$t('name')" v-model="formData.name" />
                <div class="invalid-feedback">{{ this?.errors?.name }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('domain_master') }}</CFormLabel>
                <select-vue
                    @change="this.liveValidation('domain_master')"
                    v-model="formData.domain_master"
                    :table='"organizations"'
                    :disabled="this.disabledAttribute"
                    :placeholder="$t('domain_master')"
                    :errors="this.errors?.domain_master"
                    :class="this.errors?.domain_master ? 'form-control is-invalid' : ''"
                ></select-vue>
                <div class="invalid-feedback">{{ this?.errors?.domain_master }}</div>
            </CCol>
        </CRow>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('created_at') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute"
                    :placeholder="$t('created_at')" :aria-label="$t('created_at')"
                    :class="{ 'is-invalid': this?.errors?.created_at }" :aria-describedby="$t('created_at')"
                    :value="this.getLocaleDate(formData.created_at)" />
                <div class="invalid-feedback">{{ this?.errors?.created_at }}</div>
            </CCol>
        </CRow>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('updated_at') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute"
                    :placeholder="$t('updated_at')" :aria-label="$t('updated_at')"
                    :class="{ 'is-invalid': this?.errors?.updated_at }" :aria-describedby="$t('updated_at')"
                    :value="this.getLocaleDate(formData.updated_at)" />
                <div class="invalid-feedback">{{ this?.errors?.updated_at }}</div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
import FormSystemsMixin from '@/mixins/FormSystemsMixin';
import ValidationSchema from '@/schemas/DomainsSchema';
import SelectDropDown from '@/fields/SelectDropDown.vue';

export default {
    name: 'FormDomains',
    components: {
        'select-vue': SelectDropDown,
    },
    mixins: [ValidationErrorMixin, ValidationSuccessMixin, FormSystemsMixin],
    data() {
        return {
            dataURL: 'api/v1/domains',
            ValidationSchema: ValidationSchema,
            formData: {
                name: '',
                domain_master: '',
            },
            errors: {
                name: null,
                domain_master: null,
            },
        }
    },
    methods: {

    },
    i18n: {
        messages: {
            en: {
                id: 'ID',
                domain_master: 'Domain Master',
                name: 'Name',
                no_data: 'Please enter information here',
            },
            np: {
                id: 'आईडी',
                domain_master: 'डोमेन मास्टर',
                name: 'नाम',
                no_data: 'यहाँ डाटा राख्नुहोस्',
            },
            jp: {
                id: 'ID',
                domain_master: 'ドメインマスター',
                name: '名前',
                no_data: 'ここに情報を入力してください',
            },
        }
    },
}
</script>
