<template>
    <CRow class="mb-4 mt-4">
        <CCol :xs="12">
            <CRow class="justify-content-end mt-2">
                <CCol :xs="12" :md="5" :lg="4">
                    <CFormLabel>{{ $t('intlCode') }}</CFormLabel>
                    <v-select class="w-100 limitHeight" v-model="phoneNumber.intlCode" :placeholder="$t('intlCode')"
                        :searchable="true" :disabled="this.disable" @click="unlockCountryLock"
                        :class="{ 'is-invalid': this.errors?.intlCode }" @search="debounceSearchISDCode" @blur="lockCountryLock"
                        :options="this.intlCodeOptions">
                        <template #no-options>{{ $t('no_data') }}</template>
                    </v-select>
                    <div class="invalid-feedback">{{ this.errors?.intlCode }}</div>
                </CCol>
                <CCol :xs="12" :md="7" :lg="6">
                    <CFormLabel>{{ $t('phone') }}</CFormLabel>
                    <CFormInput :maxlength="10" :placeholder="$t('phone')" :aria-label="$t('phone')"
                        :class="{ 'is-invalid': this.errors?.phone }" :aria-describedby="$t('phone')"
                        v-model="phoneNumber.phone" />
                    <div class="invalid-feedback">{{ this.errors?.phone }}</div>
                </CCol>
                <CCol :xs="12" :md="12" :lg="2">
                    <CFormLabel>{{ $t('ext') }}</CFormLabel>
                    <CFormInput :maxlength="6" :placeholder="$t('ext')" :aria-label="$t('ext')"
                        :class="{ 'is-invalid': this.errors?.extension }" :aria-describedby="$t('ext')"
                        v-model="phoneNumber.extension" />
                    <div class="invalid-feedback">{{ this.errors?.extension }}</div>
                </CCol>
            </CRow>
            <CRow class="justify-content-end mt-2">
            </CRow>
        </CCol>
    </CRow>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import PhoneNumberSchema from '@/schemas/PhoneNumberSchema';
import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
import { useStore } from 'vuex';

 /* eslint-disable no-unused-vars*/
export default {
    name: 'PhoneNumber',
    components: {},
    props: {
        modelValue: Object,
    },
    mixins: [ValidationErrorMixin, ValidationSuccessMixin],
    data() {
        return {
            timer: null,
            disable: false,
            clickCount: 0,
            search: '',
            phoneNumber: {
                intlCode: null,
                phone: null,
                extension: null,
            },
            errors: {
                extension: null,
                intlCode: null,
                phone: null,
            },
            debounceSearchISDCode: this.$gh.debounce(this.searchISDCode, 200),
            intlCodeOptions: [],
        };
    },
    setup() {
        const ctx = getCurrentInstance();
        ctx.appContext.config.globalProperties.$gh.boot();

        const store = useStore();
        return {
            loading: computed(() => store.state.core.loading),
            commit: store.commit,
            dispatch: store.dispatch,
        };
    },
    computed: {
        urlPhone: function () {
            return `/settings/find-phone?q=${this.search}`;
        },
    },
    mounted: function () {
        this.boot();
    },
    methods: {
        resetClickState: function () {
            clearTimeout(this.timer);
            this.timer = null;
            this.clickCount = 0;
        },
        lockCountryLock: function () {
            this.resetClickState();
            this.disable = true;
        },
        unlockCountryLock() {
            this.clickCount++;
            if (this.clickCount === 1) {
                this.clickTimer = setTimeout(() => {
                    this.resetClickState();
                }, 200);
            } else if (this.clickCount === 2) {
                clearTimeout(this.clickTimer);
                this.clickTimer = null;
            } else if (this.clickCount === 3) {
                this.disable = false;
                this.resetClickState();
            }
        },
        selectByCountry: function (country) {
            if ('' === country.code) {
                return true;
            }

            this.commit('core/updateLoading', { value: true }, { root: true });
            this.$gh.post(this.urlPhone + country.code).then((response) => {
                this.fetchedOptions = response.data.items;
                if (1 === this.fetchedOptions.length) {
                    this.phoneNumber.intlCode = this.fetchedOptions[0];
                }
            }).finally(() => {
                this.commit('core/updateLoading', { value: false }, { root: true });
                this.disable = true;
            });
        },
        searchISDCode: function (search, loading) {
            if (search.length < 2) {
                return true;
            }

            loading(true);
            this.commit('core/updateLoading', { value: true }, { root: true });
            this.search = search;
            this.$gh.post(this.urlPhone).then((response) => {
                this.intlCodeOptions = response.data.items;
            }).catch(e => null).finally(() => {
                loading(false);
                search = '';
                this.commit('core/updateLoading', { value: false }, { root: true });
            });
        },
        resetPhoneData: function () {
            this.phoneNumber = {
                intlCode: null,
                phone: null,
                extension: null,
            };
        },
        resetErrors: function () {
            this.errors = {
                extension: null,
                intlCode: null,
                phone: null,
            };
        },
        boot: function () {
            if (this.modelValue) {
                this.phoneNumber = this.modelValue;
            } else {
                this.resetPhoneData();
            }
        },
        resolvePhoneNumber: function () {
            this.$emit('update:ev_phone', this.phoneNumber)
            this.disable = false;
            this.resetPhoneData();
            this.$emit('update:modelValue', this.phoneNumber)
            this.resetErrors();
            this.issueSuccessMessage();
        },
        submit: function () {
            PhoneNumberSchema
                .validate(this.phoneNumber, { abortEarly: false })
                .then(this.resolvePhoneNumber)
                .catch(this.handleErrors);
        },
    },
    i18n: {
        messages: {
            en: {
                no_data: 'Please enter information here',
                intlCode: 'Country code',
                phone: 'Phone number',
                ext: 'Extension',
            },
            np: {
                no_data: 'यहाँ डाटा राख्नुहोस्',
                intlCode: 'देश कोड',
                phone: 'फोन नम्बर',
                ext: 'एक्सटेन्सन',
            },
            jp: {
                no_data: 'ここに情報を入力してください',
                intlCode: '国番号',
                phone: '国番号',
                ext: 'コード',
            },
        },
    },
};
</script>
