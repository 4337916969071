<template>
    <div class="latch-1000">
        <ModalComponent size="xl" v-model:visible="isModalVisible">
            <template #modal-title>
                {{ $t(`forms.${this?.formAction}`) }} - {{ $t('forms.FormBookings') }}
            </template>

            <template #close>
                <CButton @click="hideModal" type="button" aria-label="Close" class="close">×</CButton>
            </template>

            <template #modal-body>
                <div class="latch-2000">
                    <FormBookings ref="FormBookings" @operation="this.handleFormOperationVerb" :odataEndpoint="this.odataEndpoint" @close-modal="hideModal" @refreshTable="this.emitRefresh"></FormBookings>
                </div>
            </template>

        </ModalComponent>
    </div>
</template>

<script>
import ModalSystemsMixin from '@/mixins/ModalSystemsMixin';

export default {
    name: 'ModalBookings',
    mixins: [ModalSystemsMixin],
    data() {
        return {
            refComponent: 'FormBookings',
        };
    },
};
</script>
