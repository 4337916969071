import ModalComponent from '@/modals/base/BaseModal.vue';

export default {
    components: {
        ModalComponent
    },
    props: {
        odataEndpoint: {
            type: String,
            required: true
        }
    },
    methods: {
        enableEdit(selectedRecord) {
            this.showModal();
            this.$nextTick(() => {
                this.$refs[this.refComponent].enableEdit(selectedRecord);
            });
        },
        enableCreate() {
            this.showModal();
            this.$nextTick(() => {
                this.$refs[this.refComponent].enableCreate();
            });
        },
        enableView(selectedRecord) {
            this.showModal();
            this.$nextTick(() => {
                this.$refs[this.refComponent].enableView(selectedRecord);
            });
        },
        resolveSubmission() {
            this.$nextTick(() => {
                this.$refs[this.refComponent].submit();
            });
        },
        showModal() {
            this.isModalVisible = true;
        },
        hideModal() {
            this.isModalVisible = false;
        },
        handleFormOperationVerb(operation) {
            this.formAction = operation;
        },
        emitRefresh() {
            this.$emit('refreshTable');
        }
    },
    data: function () {
        return {
            isModalVisible: false,
            formAction: ''
        };
    },
};
