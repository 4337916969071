<template>
    <v-select ref="select" class="w-100 limitHeight" v-model="this.trueValue" :placeholder="this.placeholder"
        @option:selected="() => this.$emit('update:modelValue', this.trueValue.code)"
        :searchable="true" :disabled="this.disabled" :class="{ 'is-invalid': errors }" @search="searchQuery"
        :options="this.searchOptions">
        <template #no-options>{{ $t('no_data') }}</template>
    </v-select>

</template>

<script>
import { getCurrentInstance } from 'vue';

 /* eslint-disable no-unused-vars*/
export default {
    name: 'SelectDropDown',
    props: {
        modelValue: {
            default: null,
        },
        table: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        errors: {
            default: null,
        },
    },
    watch: {
        'modelValue': {
            deep: true,
            handler: function (newValue) {
                if (newValue) {
                    this.$gh.post(`settings/find/${this.table}?q=${newValue}`).then((response) => {
                        this.trueValue = response.data.items.find((item) => item.code === this.modelValue);
                    }).catch(e => null);
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            disabledDuringType: false,
            trueValue: null,
            search: '',
            searchOptions: [],
        }
    },
    setup() {
        const ctx = getCurrentInstance()
        ctx.appContext.config.globalProperties.$gh.boot();
    },
    computed: {
        urlQuery: function () {
            return `/settings/find/${this.table}?q=${this.search}`;
        },
    },
    methods: {
        searchQuery: function (search, loading) {
            if (search.length < 2) {
                return true;
            }

            if (this.disabledDuringType) {
                return true;
            }

            loading(true);
            this.showLoading();
            this.disabledDuringType = true;
            this.search = search;
            this.$gh.post(this.urlQuery).then((response) => {
                this.searchOptions = response.data.items;
            }).catch(e => null).finally(() => {
                loading(false);
                search = '';
                this.hideLoading();
                this.disabledDuringType = false;
            });
        },
        showLoading: function () {
            this.$gh.showLoading();
        },
        hideLoading: function () {
            this.$gh.hideLoading();
        },
    },
    i18n: {
        messages: {
            en: {
                no_data: 'Please enter information here',
            },
            np: {
                no_data: 'यहाँ डाटा राख्नुहोस्',
            },
            jp: {
                no_data: 'ここに情報を入力してください',
            },
        }
    },
}
</script>
