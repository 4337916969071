import * as yup from 'yup';

export default yup.object().shape({
    domain_id: yup.string().required({ message: 'v.required' }),
    tax_id: yup.string().required({ message: 'v.required' }),
    address: yup.string().required({ message: 'v.required' }),
    email: yup.string().required({ message: 'v.required' }),
    phone: yup.string().required({ message: 'v.required' }),
    city: yup.string().required({ message: 'v.required' }),
    state: yup.string().required({ message: 'v.required' }),
    country: yup.string().required({ message: 'v.required' }),
    zip_code: yup.string().required({ message: 'v.required' }),
    postal_code: yup.string().required({ message: 'v.required' }),
    website: yup.string().required({ message: 'v.required' }),
    locale: yup.string().required({ message: 'v.required' }),
    currency: yup.string().required({ message: 'v.required' }),
    currency_symbol: yup.string().required({ message: 'v.required' }),
    logo_url: yup.string().required({ message: 'v.required' }),
});
