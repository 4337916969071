<template>
    <CRow>
        <CCol :xs="12" class="mb-2">
            <CRow>
                <CCol :xs="12">
                    <p class="text-medium-emphasis">{{ $t('hsCode') }}</p>
                    <CInputGroup>
                        <v-select v-model="this.selectedHSCode" :placeholder="$t('hsCode')" class="w-100"
                            :filterable="false" @focus="this.alert = false" @search:blur="handleBlur"
                            @option:deselected="handleBlur" @search="this.debouncedSearchHSCode"
                            :options="this.HSCodeCandidateOptions">
                            <template #no-options>{{ $t('no_data') }}</template>
                            <template #option="{ code, label, top_parent }">
                                <div style="white-space: normal;">
                                    <b>{{ code }} : {{ top_parent }}</b><br />
                                    <small><cite>{{ label }}</cite></small>
                                </div>
                            </template>
                            <template #selected-option="{ code, label, top_parent }">
                                <div class="d-flex flex-column">
                                    <div class="d-flex align-items-start">
                                        <span class="mr-2"><b>{{ code }}</b> &nbsp; </span>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <small><span class="font-weight-bold">{{ top_parent }}</span></small>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <small><cite>{{ label }}</cite></small>
                                    </div>
                                </div>
                            </template>
                        </v-select>
                    </CInputGroup>
                    <CAlert color="warning" class="mt-2 w-100">{{ $t('message_hs_code') }}</CAlert>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

 /* eslint-disable no-unused-vars*/
export default {
    name: 'HSCode',
    components: {
    },
    data() {
        return {
            search: '',
            alert: false,
            offset: 0,
            limit: 10,
            selectedHSCode: [],
            HSCodeCandidateOptions: [],
            suffix: '',
            debouncedSearchHSCode: this.$gh.debounce(this.searchHSCode, 200),
        }
    },
    watch: {
        selectedHSCode: function (newValue) {
            this.$emit('update:modelValue', newValue);
        },
    },
    setup() {
        const ctx = getCurrentInstance()
        ctx.appContext.config.globalProperties.$gh.boot();

        const store = useStore();
        return {
            loading: computed(() => store.state.core.loading),
            commit: store.commit,
            dispatch: store.dispatch,
        }
    },
    computed: {
        urlHSCodeLookup: function () {
            return `/settings/find-hs-code?q=${this.search}`;
        },
    },
    methods: {
        handleBlur: function () {
            if (this.selectedHSCode?.code) {
                this.alert = false;
                return;
            }

            this.alert = true;
        },
        searchHSCode: function (search, loading) {
            if (search.length <= 2) {
                return true;
            }

            loading(true);
            this.search = search;
            this.commit('core/updateLoading', { value: true }, { root: true });
            this.$gh.post(this.urlHSCodeLookup).then((response) => {
                this.HSCodeCandidateOptions = response.data.items;
            }).catch(e => null).finally(() => {
                loading(false);
                this.commit('core/updateLoading', { value: false }, { root: true });
            });
        },
    },
    i18n: {
        messages: {
            en: {
                no_data: 'Please enter 2 or more letters to continue',
                hsCode: 'HS Code',
                suffix: 'HS Code Suffix',
                message_hs_code: "You can use specific terms or a numeric HS code to search in the HS code directory. Separate multiple terms with commas.",
            },
            np: {
                no_data: '2 वा बढी अक्षरहरू टाइप गर्नुहोस्',
                hsCode: 'HS कोड',
                suffix: 'HS कोड सफिक्स',
                message_hs_code: "तपाईं विशेष शब्दहरू वा एक संख्यात्मक एचएस कोडलाई सीधै खोजीमा प्रयोग गर्न सक्नुहुन्छ। कमा छुट्टै शब्दहरूलाई कमा सिपारेट गर्नका लागि कमा उपयोग गर्नुहोस्।",

            },
            jp: {
                no_data: '2文字以上入力してください',
                hsCode: 'HSコード',
                message_hs_code: "特定の用語または数値のHSコードを使用して、HSコードディレクトリで検索できます。複数の用語をカンマで区切ってください。"
            },
        }
    },
}
</script>
