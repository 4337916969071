<template>
    <CInputGroup>
        <autocomplete-field
            v-model="this.data"
            @change="this.registerChange"
            minLength="2"
            :allowCustom="this.allowCustom" :autofill='true'
            @filtering="searchLookup"
            :readonly="this.disabled"
            :fields='this.fieldInformation'
            :query='query' :highlight="true"
            :placeholder="this.waterMark">
        </autocomplete-field>
    </CInputGroup>
</template>

<script>
import { AutoCompleteComponent } from '@syncfusion/ej2-vue-dropdowns';
import { Query, DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';

export default {
    name: 'InputAutoComplete',
    props: {
        db: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowCustom: {
            type: Boolean,
            default: true,
        },
        modelValue: String
    },
    components: {
        'autocomplete-field': AutoCompleteComponent,
    },
    data: function () {
        return {
            data: '',
            searchLookup: this.debounce(this.updateData, 200),
        }
    },
    mounted: function () {
        if ( this.modelValue ) {
            this.data = this.modelValue;
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.data = newValue;
        }
    },
    methods: {
        getQuery: function (e) {
            return new Query().where('filter', 'startsWith', e.text) ;
        },
        registerChange: function() {
            this.$emit('update:modelValue', this.data);
        },
        updateData: function (e) {
            e.updateData(this.dataManager, this.getQuery(e));
        },
        debounce: function(func, wait, immediate) {
            let timeout;
            return function() {
                var context = this, args = arguments;
                clearTimeout(timeout);
                if (immediate && !timeout) func.apply(context, args);
                timeout = setTimeout(function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
                }, wait);
            };
        },
    },
    computed: {
        query: function () {
            return new Query();
        },
        dataManager: function () {
            return new DataManager({
                url: this.urlFilter,
                adaptor: new WebApiAdaptor,
                headers: [{ Authorization: 'Bearer ' + this.token }],
            });
        },
        urlFilter: function () {
            return `/api/settings/autocomplete/${this.db.model}?field=${this.db.field}`;
        },
        fieldInformation: function () {
            return { value: 'label' };
        },
        waterMark: function () {
            return this.$t('no_data');
        },
        token: function () {
            return this.$gh.getToken();
        },
    },
    i18n: {
        messages: {
            en: {
                no_data: 'Please enter 2 or more letters to continue',
            },
            np: {
                no_data: '2 वा बढी अक्षरहरू टाइप गर्नुहोस्',
            },
            jp: {
                no_data: '2文字以上入力してください',
            },
        }
    },
}
</script>
