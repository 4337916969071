<template>
    <div :key="this.refIndexKey">
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('id') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :placeholder="$t('id')" :aria-label="$t('id')"
                    :aria-describedby="$t('id')" v-model="formData.id" />
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('name') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('name')" :disabled="this.disabledAttribute" :placeholder="$t('name')" :aria-label="$t('name')"
                    :class="{ 'is-invalid': this?.errors?.name }" :aria-describedby="$t('name')" v-model="formData.name" />
                <div class="invalid-feedback">{{ this?.errors?.name }}</div>
            </CCol>
        </CRow>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('created_at') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('created_at')" :disabled="this.disabledAttribute" :placeholder="$t('created_at')" :aria-label="$t('created_at')"
                    :class="{ 'is-invalid': this?.errors?.created_at }" :aria-describedby="$t('created_at')" :value="this.getLocaleDate(formData.created_at)" />
                <div class="invalid-feedback">{{ this?.errors?.created_at }}</div>
            </CCol>
        </CRow>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('updated_at') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('updated_at')" :disabled="this.disabledAttribute" :placeholder="$t('updated_at')" :aria-label="$t('updated_at')"
                    :class="{ 'is-invalid': this?.errors?.updated_at }" :aria-describedby="$t('updated_at')" :value="this.getLocaleDate(formData.updated_at)" />
                <div class="invalid-feedback">{{ this?.errors?.updated_at }}</div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
    import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
    import FormSystemsMixin from '@/mixins/FormSystemsMixin';
    import ValidationSchema from '@/schemas/AccountGroupsSchema';

    export default {
        name: 'FormAccountGroups',
        components: {},
        mixins: [ValidationErrorMixin, ValidationSuccessMixin, FormSystemsMixin],
        data() {
            return {
                dataURL: 'api/v1/account-group',
                ValidationSchema: ValidationSchema,
                formData: {
                    id: '',
                    name: '',
                },
                errors: {
                    id: null,
                    name: null,
                },
            }
        },
        computed: {},
        methods: {
            resolveSubmission: function () {
                this.resetErrors();
                this.refIndexKey++;
                this.boot();
            },
        },
        i18n: {
            messages: {
                en: {
                    id: 'ID',
                    name: 'Account Group Name',
                },
                np: {
                    id: 'आईडी',
                    name: 'खाता समूह नाम',
                },
                jp: {
                    id: 'ID',
                    name: 'アカウントグループ名',
                },
            }
        },
    }
</script>
