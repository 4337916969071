import { computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        odataEndpoint: {
            type: String,
            required: true
        }
    },
    methods: {
        loadData: function (data) {
            this.formData = data;
        },
        conditionalTab: function () {
            // Check the kind of operation.
            if (this.operation === 'create') {
                this.tabsDisabled = true;
            }

            this.tabsDisabled = false;
        },
        showLoading: function () {
            this.$gh.showLoading();
        },
        hideLoading: function () {
            this.$gh.hideLoading();
        },
        resetErrors: function (field = null) {
            if (field) {
                return this.errors[field] = null;
            }
            for (let key in this.errors) {
                this.errors[key] = null;
            }
        },
        getLocaleDate: function (date) {
            return new Date(date).toLocaleString(this.$gh.getIntlCode());
        },
        fetchData: function ($id) {
            this.showLoading();
            let endpoint = this.$gh.getOdataEndpoint(this.odataEndpoint) + `(${$id})`;

            if (this?.endpointOverride) {
                endpoint = this.$gh.getOdataEndpoint(this.odataEndpoint) + `?$filter=id eq ${$id}`;
            }

            this.$gh.get(endpoint)
                .then((response) => {
                    if (response?.data?.value && Array.isArray(response.data.value)) {
                        response.data = response.data.value[0];
                    }

                    this.loadData(response.data);
                    if (this.childData) {
                        Object.keys(this.childData).forEach((child) => {
                            this.$gh.get(this.$gh.getOdataEndpoint(this.odataEndpoint) + `(${$id})/${this.childData[child]}`)
                                .then((response) => {
                                    this.formData[child] = response.data.value;
                                })
                                .catch((e) => {
                                    this.$store.dispatch('alert/error', { message: this.$t('form_action.FormRecordFetchFailure') });
                                    return this.$emit('close-modal');
                                })
                        });
                    }
                })
                .catch((e) => {
                    this.$store.dispatch('alert/error', { message: this.$t('form_action.FormRecordFetchFailure') });
                })
                .finally(() => this.hideLoading());
        },
        liveValidation: function (field) {
            try {
                this.resetErrors(field);
                this.ValidationSchema.validate(this.formData, { abortEarly: false })
                    .catch(e => this.handleErrors(e, false));
            } catch (e) {
                this.$gh.log(e);
            }
        },
        create: function (submission) {
            if (this.createOverride && typeof this.createOverride === 'function') {
                return this.createOverride(submission);
            }

            this.showLoading();
            delete submission.id;
            if (this.reduceSubmission && typeof this.reduceSubmission === 'object') {
                try {
                    this.reduceSubmission.forEach((key) => delete submission[key]);
                } catch (e) { /* eslint-disable no-unused-vars*/ }
            }

            this.$gh.post(this.$gh.getOdataEndpoint(this.odataEndpoint), submission)
                .then(() => {
                    this.$emit('refreshTable');
                    this.$store.dispatch('alert/success', { message: this.$t('form_action.FormRecordCreatedSuccess') });

                    if (this.postCreateHook && typeof this.postCreateHook === 'function') {
                        return this.postCreateHook(submission);
                    }

                    return true;
                })
                .catch((e) => {
                    return this.$store.dispatch('alert/error', { message: this.$t('form_action.FormRecordCreatedFailure') });
                }).finally(
                    () => {
                        this.hideLoading();
                        this.$emit('close-modal');
                    }
                );

        },
        update: function (submission) {
            if (this.updateOverride && typeof this.updateOverride === 'function') {
                return this.updateOverride(submission);
            }

            if (this.createOverride && typeof this.createOverride === 'function') {
                return this.createOverride(submission);
            }

            this.showLoading();
            let id = submission.id;
            delete submission.id;
            if (this.reduceSubmission && typeof this.reduceSubmission === 'object') {
                try {
                    this.reduceSubmission.forEach((key) => delete submission[key]);
                } catch (e) { /* eslint-disable no-unused-vars*/ }
            }

            this.$gh.patch(`${this.$gh.getOdataEndpoint(this.odataEndpoint)}/${id}`, submission)
                .then(() => {
                    this.$emit('refreshTable');
                    this.$store.dispatch('alert/success', { message: this.$t('form_action.FormRecordCreatedSuccess') });

                    if (this.postCreateHook && typeof this.postCreateHook === 'function') {
                        return this.postCreateHook(submission);
                    }

                    return true;
                })
                .catch((e) => {
                    return this.$store.dispatch('alert/error', { message: this.$t('form_action.FormRecordCreatedFailure') });
                }).finally(
                    () => {
                        this.hideLoading();
                        this.$emit('close-modal');
                    }
                );
        },
        submit() {
            this.disabledAttribute = true;
            this.resetErrors();
            let validator = this.ValidationSchema;
            let submission = this.formData;
            let solution = null;

            switch (this.operation) {
                case 'create':
                    if (this.create && typeof this.create === 'function') {
                        solution = this.create;
                        break;
                    }
                    return this.$gh.log('[Function] Implement not found for CREATE');
                case 'update':
                    if (this.update && typeof this.update === 'function') {
                        solution = this.update;
                        break;
                    }
                    return this.$gh.log('[Function] Implement not found for EDIT');
                default:
                    break;
            }
            validator.validate(submission, { abortEarly: false })
                .then(() => solution(submission))
                .catch(this.handleErrors)
                .finally(() => this.disabledAttribute = false);
        },
        boot() {
            for (let key in this.formData) {
                this.formData[key] = null;
            }
        },
        enableEdit: function (data) {
            this.operation = 'update';
            if (data?.id) {
                this.fetchData(data?.id);
            }
            this.$emit('operation', this.operation)
            this.loadData(data);
        },
        enableCreate: function () {
            this.operation = 'create';
            this.$emit('operation', this.operation)
        },
        enableView: function (data) {
            this.operation = 'view';
            if (data?.id) {
                this.fetchData(data?.id);
            }
            this.$emit('operation', this.operation)
            this.disabledAttribute = true;
            this.loadData(data);
        },
        showFieldView: function () {
            return ['view'].includes(this.operation) ? true : false;
        },
    },
    setup: function () {
        const ctx = getCurrentInstance()
        ctx.appContext.config.globalProperties.$gh.boot();

        const store = useStore();
        return {
            loading: computed(() => store.state.core.loading),
            commit: store.commit,
            dispatch: store.dispatch,
        }
    },
    mounted() {
        this.$gh.boot();
        if (typeof this.onMounted === 'function') {
            this.onMounted();
        }
    },
    data: function () {
        return {
            operation: 'unset',
            tabsDisabled: false,
            disabledAttribute: false,
            refIndexKey: 0,
        };
    },
};
