import * as yup from 'yup';

const phoneSchema = yup
    .string()
    .matches(/^\d{6,10}$/, { message: { message: 'v.between', template: {'a': 6, 'b': 10} }})
    .required({ message: 'v.required' });

const extensionSchema = yup
    .string()
    .matches(/^\d{1,6}$/, { message: { message: 'v.between', template: {'a': 1, 'b': 6} }})
    .notRequired();

const intlCodeSchema = yup.object({
    code: yup
        .string()
        .matches(/^\+\d+$/, { message: 'v.digits' })
        .required({ message: 'v.required' }),
    label: yup.string(),
});

const PhoneNumberSchema = yup.object({
    intlCode: intlCodeSchema.required({ message: 'v.required' }),
    phone: phoneSchema,
    extension: extensionSchema,
});

export default PhoneNumberSchema;
