<template>
    <div :key="this.refIndexKey">
        <CRow class="justify-content mb-4">
            <CCol v-if="this.showFieldView()" :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('id') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('id')" :aria-label="$t('id')"
                    :class="{ 'is-invalid': this?.errors?.id }" :aria-describedby="$t('id')" v-model="formData.id" />
                <div class="invalid-feedback">{{ this?.errors?.id }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('name') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('name')" :disabled="this.disabledAttribute"
                    :placeholder="$t('name')" :aria-label="$t('name')" :class="{ 'is-invalid': this?.errors?.name }"
                    :aria-describedby="$t('name')" v-model="formData.name" />
                <div class="invalid-feedback">{{ this?.errors?.name }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('domain_id') }}</CFormLabel>
                <select-vue
                @change="this.liveValidation('domain_id')"
                v-model="formData.domain_id"
                :table='"domains"'
                :disabled="this.disabledAttribute"
                :placeholder="$t('domain_id')"
                :errors="this.errors?.domain_id"
                ></select-vue>
                <div class="invalid-feedback">{{ this?.errors?.domain_id }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('tax_id') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('tax_id')" :disabled="this.disabledAttribute"
                    :placeholder="$t('tax_id')" :aria-label="$t('tax_id')" :class="{ 'is-invalid': this?.errors?.tax_id }"
                    :aria-describedby="$t('tax_id')" v-model="formData.tax_id" />
                <div class="invalid-feedback">{{ this?.errors?.tax_id }}</div>
            </CCol>
        </CRow>
        <CCol class="justify-content mb-4" :xs="12" :md="12" :lg="12">
            <CFormLabel>{{ $t('address') }}</CFormLabel>
            <CFormInput @keyup="this.liveValidation('address')" :disabled="this.disabledAttribute"
                :placeholder="$t('address')" :aria-label="$t('address')" :class="{ 'is-invalid': this?.errors?.address }"
                :aria-describedby="$t('address')" v-model="formData.address" />
            <div class="invalid-feedback">{{ this?.errors?.address }}</div>
        </CCol>
        <CCol class="justify-content mb-4" :xs="12" :md="12" :lg="12">
            <CFormLabel>{{ $t('email') }}</CFormLabel>
            <CFormInput @keyup="this.liveValidation('email')" :disabled="this.disabledAttribute"
                :placeholder="$t('email')" :aria-label="$t('email')" :class="{ 'is-invalid': this?.errors?.email }"
                :aria-describedby="$t('email')" v-model="formData.email" />
            <div class="invalid-feedback">{{ this?.errors?.email }}</div>
        </CCol>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('phone') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('phone')" :disabled="this.disabledAttribute"
                    :placeholder="$t('phone')" :aria-label="$t('phone')" :class="{ 'is-invalid': this?.errors?.phone }"
                    :aria-describedby="$t('phone')" v-model="formData.phone" />
                <div class="invalid-feedback">{{ this?.errors?.phone }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('city') }}</CFormLabel>
                <text-field v-model="formData.city" :class="{ 'is-invalid': this?.errors?.city }" :disabled="this.disabledAttribute" :db="{ model: 'city', field: 'name' }"></text-field>
                <div class="invalid-feedback">{{ this?.errors?.city }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('state') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('state')" :disabled="this.disabledAttribute"
                    :placeholder="$t('state')" :aria-label="$t('state')" :class="{ 'is-invalid': this?.errors?.state }"
                    :aria-describedby="$t('state')" v-model="formData.state" />
                <div class="invalid-feedback">{{ this?.errors?.state }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('country') }}</CFormLabel>
                <text-field v-model="formData.country" :class="{ 'is-invalid': this?.errors?.country }" :disabled="this.disabledAttribute" :db="{ model: 'country', field: 'name' }"></text-field>
                <div class="invalid-feedback">{{ this?.errors?.country }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('zip_code') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('zip_code')" :disabled="this.disabledAttribute"
                    :placeholder="$t('zip_code')" :aria-label="$t('zip_code')" :class="{ 'is-invalid': this?.errors?.zip_code }"
                    :aria-describedby="$t('zip_code')" v-model="formData.zip_code" />
                <div class="invalid-feedback">{{ this?.errors?.zip_code }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('postal_code') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('postal_code')" :disabled="this.disabledAttribute"
                    :placeholder="$t('postal_code')" :aria-label="$t('postal_code')" :class="{ 'is-invalid': this?.errors?.postal_code }"
                    :aria-describedby="$t('postal_code')" v-model="formData.postal_code" />
                <div class="invalid-feedback">{{ this?.errors?.postal_code }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('website') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('website')" :disabled="this.disabledAttribute"
                    :placeholder="`https://`" :aria-label="$t('website')" :class="{ 'is-invalid': this?.errors?.website }"
                    :aria-describedby="$t('website')" v-model="formData.website" />
                <div class="invalid-feedback">{{ this?.errors?.website }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('currency') }}</CFormLabel>
                <text-field :class="{ 'is-invalid': this?.errors?.currency }" v-model="formData.currency" :disabled="this.disabledAttribute" :db="{ model: 'currency', field: 'name' }"></text-field>
                <div class="invalid-feedback">{{ this?.errors?.currency }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('currency_symbol') }}</CFormLabel>
                <text-field v-model="formData.currency_symbol" :class="{ 'is-invalid': this?.errors?.currency_symbol }" :disabled="this.disabledAttribute" :db="{ model: 'currency', field: 'currency' }"></text-field>
                <div class="invalid-feedback">{{ this?.errors?.currency_symbol }}</div>
            </CCol>
        </CRow>
        <CCol class="justify-content mb-4" :xs="12" :md="12" :lg="12">
            <CFormLabel>{{ $t('logo_url') }}</CFormLabel>
            <CFormInput @keyup="this.liveValidation('logo_url')" :disabled="this.disabledAttribute"
                :placeholder="`https://`" :aria-label="$t('logo_url')" :class="{ 'is-invalid': this?.errors?.logo_url }"
                :aria-describedby="$t('logo_url')" v-model="formData.logo_url" />
            <div class="invalid-feedback">{{ this?.errors?.logo_url }}</div>
        </CCol>
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('created_at') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('created_at')" :disabled="this.disabledAttribute" :placeholder="$t('created_at')" :aria-label="$t('created_at')"
                    :class="{ 'is-invalid': this?.errors?.created_at }" :aria-describedby="$t('created_at')" :value="this.getLocaleDate(formData.created_at)" />
                <div class="invalid-feedback">{{ this?.errors?.created_at }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('updated_at') }}</CFormLabel>
                <CFormInput @keyup="this.liveValidation('updated_at')" :disabled="this.disabledAttribute" :placeholder="$t('updated_at')" :aria-label="$t('updated_at')"
                    :class="{ 'is-invalid': this?.errors?.updated_at }" :aria-describedby="$t('updated_at')" :value="this.getLocaleDate(formData.updated_at)" />
                <div class="invalid-feedback">{{ this?.errors?.updated_at }}</div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
    import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
    import FormSystemsMixin from '@/mixins/FormSystemsMixin';
    import ValidationSchema from '@/schemas/IndividualsSchema';
    import SelectVue from '@/fields/SelectDropDown.vue';
    import InputAutoComplete from '@/fields/InputAutoComplete.vue';


    export default {
        name: 'FormIndividuals',
        components: {
            'select-vue': SelectVue,
            'text-field': InputAutoComplete,
        },
        mixins: [ValidationErrorMixin, ValidationSuccessMixin, FormSystemsMixin],
        data() {
            return {
                dataURL: 'api/v1/individuals',
                ValidationSchema: ValidationSchema,
                formData: {
                    id: '',
                    domain_id: null,
                    tax_id: '',
                    address: '',
                    email: '',
                    phone: '',
                    city: '',
                    state: '',
                    country: '',
                    zip_code: '',
                    postal_code: '',
                    website: '',
                    locale: '',
                    currency: '',
                    currency_symbol: '',
                    logo_url: '',
                },
                errors: {
                    domain_id: null,
                    tax_id: null,
                    address: null,
                    email: null,
                    phone: null,
                    city: null,
                    state: null,
                    country: null,
                    zip_code: null,
                    postal_code: null,
                    website: null,
                    locale: null,
                    currency: null,
                    currency_symbol: null,
                    logo_url: null,
                }
            }
        },
        computed: {},
        methods: {
            resolveSubmission: function () {
                this.resetErrors();
                this.refIndexKey++;
                this.boot();
            }
        },
        i18n: {
            messages: {
                en: {
                    id: 'ID',
                    name: 'Organization Name',
                    domain_id: 'Domain ID',
                    tax_id: 'Tax ID',
                    address: 'Address',
                    email: 'Email',
                    phone: 'Phone',
                    city: 'City',
                    state: 'State',
                    country: 'Country',
                    zip_code: 'Zip Code',
                    postal_code: 'Postal Code',
                    website: 'Website',
                    locale: 'Locale',
                    currency: 'Currency',
                    currency_symbol: 'Currency Symbol',
                    logo_url: 'Logo URL',
                    created_at: 'Created At',
                    updated_at: 'Updated At',
                    no_data: 'Please enter information here',
                },
                np: {
                    id: 'आईडी',
                    name: 'संस्था नाम',
                    domain_id: 'डोमेन आईडी',
                    tax_id: 'कर आईडी',
                    address: 'ठेगाना',
                    email: 'ईमेल',
                    phone: 'फोन',
                    city: 'शहर',
                    state: 'राज्य',
                    country: 'देश',
                    zip_code: 'जिप कोड',
                    postal_code: 'पोस्टल कोड',
                    website: 'वेबसाइट',
                    locale: 'स्थानीय',
                    currency: 'मुद्रा',
                    currency_symbol: 'मुद्रा प्रतीक',
                    logo_url: 'लोगो यूआरएल',
                    created_at: 'मा सिर्जना गरिएको',
                    updated_at: 'मा अपडेट गरिएको',
                    no_data: 'कृपया यहाँ जानकारी दिनुहोस्',
                },
                jp: {
                    id: 'ID',
                    name: '組織名',
                    domain_id: 'ドメインID',
                    tax_id: '税ID',
                    address: '住所',
                    email: 'Eメール',
                    phone: '電話',
                    city: '市',
                    state: '状態',
                    country: '国',
                    zip_code: '郵便番号',
                    postal_code: '郵便番号',
                    website: 'ウェブサイト',
                    locale: 'ロケール',
                    currency: '通貨',
                    currency_symbol: '通貨記号',
                    logo_url: 'ロゴURL',
                    created_at: '作成日',
                    updated_at: '更新日',
                    no_data: 'ここに情報を入力してください',
                },
            },
        },
    }
</script>
