export async function registerGlobalComponents(app) {
    await registerComponentsFromGlob(import.meta.glob('./forms/*.vue', { eager: true }), app);
    await registerComponentsFromGlob(import.meta.glob('./modals/*.vue', { eager: true }), app);

    app.mount('#app');
}

async function registerComponentsFromGlob(files, app) {
    for (const path in files) {
        const name = path.split('/').pop().split('.')[0];
        const { default: component } = files[path];
        app.component(name, component);
    }
}
