import * as yup from 'yup';

const schema = yup.object().shape({
    air_carrier: yup.string().nullable(),
    overseas_agent: yup.string().nullable(),
    type: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'type' })
    })),
    mode: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'mode' })
    })),
    reference_number: yup.string().nullable().max(18, ({ translate }) => ({
        message: translate('v.max', { a: 18 })
    })).test('unique', ({ translate }) => ({
        message: translate('v.unique', { attribute: 'reference number' })
    }), function (value) {
        return this.parent.reference_number ? this.parent.reference_number === value : true;
    }),
    type_shipper: yup.string().nullable(),
    shipper: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'shipper' })
    })),
    type_consignee: yup.string().nullable(),
    consignee: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'consignee' })
    })),
    shipment_date: yup.date().required(({ translate }) => ({
        message: translate('v.required')
    })).typeError(({ translate }) => ({
        message: translate('v.date')
    })),
    type_bill_to_party: yup.string().nullable(),
    bill_to_party: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'bill to party' })
    })),
    type_ship_to: yup.string().nullable(),
    ship_to: yup.string().nullable(),
    type_notify: yup.string().nullable(),
    notify: yup.string().nullable(),
    needs_pickup: yup.boolean().required(({ translate }) => ({
        message: translate('v.required')
    })).typeError(({ translate }) => ({
        message: translate('v.boolean')
    })),
    origin: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'origin' })
    })),
    destination: yup.string().required(({ translate }) => ({
        message: translate('v.required')
    })).matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'destination' })
    })),
    status: yup.string().nullable().matches(/^[0-9]+$/, ({ translate }) => ({
        message: translate('v.exists', { attribute: 'status' })
    })),
});

export default schema;
