<template>
    <CModal backdrop="static" alignment="center" v-model:isModalVisible="visible">
        <div class="modal-content">
            <header class="modal-header">
                <h5 class="modal-title">
                    <slot name="modal-title"></slot>
                </h5>
                <slot name="close"></slot>
            </header>
            <div class="modal-body">
                <slot name="modal-body"></slot>
            </div>
            <footer class="modal-footer">
                <slot name="modal-footer"></slot>
            </footer>
        </div>
    </CModal>
</template>

<script>
import { ref, watch } from 'vue';

export default {
    name: 'BaseModal',
    props: ["modelValue"],
    emits: ['update:modelValue', 'close'],
    setup(props, { emit }) {
        const visible = ref(props.modelValue);

        watch(
            () => props.modelValue,
            (newValue) => {
                visible.value = newValue;
            }
        );

        watch(
            () => visible.value,
            (newValue) => {
                if (!newValue) {
                    emit('close');
                }
                emit('update:modelValue', newValue);
            }
        );

        return {
            visible,
        };
    }
};
</script>
