export default {
    methods: {
        handleErrors(errors, message = true) {
            const errorMessages = {};

            errors.inner.forEach(error => {
                try {
                    let errorMessage = error.message;
                    let translatedErrorMessage = '<!INVALID!>';

                    if (errorMessage && typeof errorMessage === 'object' && Object.keys(errorMessage).indexOf('template') !== -1) {
                        translatedErrorMessage = this.$t(errorMessage.message, errorMessage.template);
                    } else {
                        translatedErrorMessage = 'string' === typeof errorMessage.message ? this.$t(errorMessage.message) : errorMessage.message;
                    }

                    errorMessages[error.path] = translatedErrorMessage;
                } catch (e) {/* eslint-disable no-unused-vars*/}
            });

            this.errors = errorMessages;
            if (message) {
                this.$store.dispatch('alert/error', { message: this.$t('validation.error') });
            }
        },
    },
};
