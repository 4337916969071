<template>
    <div :key="this.refIndexKey">
        <CRow v-if="this.showFieldView()" class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('id') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('id')" :aria-label="$t('id')"
                    :class="{ 'is-invalid': this?.errors?.id }" :aria-describedby="$t('id')" v-model="formData.id" />
                <div class="invalid-feedback">{{ this?.errors?.id }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('user_name') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('user_name')" :aria-label="$t('user_name')"
                    :class="{ 'is-invalid': this?.errors?.user_name }" :aria-describedby="$t('user_name')" v-model="formData.user_name" />
                <div class="invalid-feedback">{{ this?.errors?.user_name }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('user_type') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('user_type')" :aria-label="$t('user_type')"
                    :class="{ 'is-invalid': this?.errors?.user_type }" :aria-describedby="$t('user_type')" v-model="formData.user_type" />
                <div class="invalid-feedback">{{ this?.errors?.user_type }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('organization_name') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('organization_name')" :aria-label="$t('organization_name')"
                    :class="{ 'is-invalid': this?.errors?.organization_name }" :aria-describedby="$t('organization_name')" v-model="formData.organization_name" />
                <div class="invalid-feedback">{{ this?.errors?.organization_name }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('domain_name') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('domain_name')" :aria-label="$t('domain_name')"
                    :class="{ 'is-invalid': this?.errors?.domain_name }" :aria-describedby="$t('domain_name')" v-model="formData.domain_name" />
                <div class="invalid-feedback">{{ this?.errors?.domain_name }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('email_verified_at') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('email_verified_at')" :aria-label="$t('email_verified_at')"
                    :class="{ 'is-invalid': this?.errors?.email_verified_at }" :aria-describedby="$t('email_verified_at')" v-model="formData.email_verified_at" />
                <div class="invalid-feedback">{{ this?.errors?.email_verified_at }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('last_login') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('last_login')" :aria-label="$t('last_login')"
                    :class="{ 'is-invalid': this?.errors?.last_login }" :aria-describedby="$t('last_login')" v-model="formData.last_login" />
                <div class="invalid-feedback">{{ this?.errors?.last_login }}</div>
            </CCol>
            <CCol :xs="12" :md="6" :lg="6">
                <CFormLabel>{{ $t('last_login_ip') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('last_login_ip')" :aria-label="$t('last_login_ip')"
                    :class="{ 'is-invalid': this?.errors?.last_login_ip }" :aria-describedby="$t('last_login_ip')" v-model="formData.last_login_ip" />
                <div class="invalid-feedback">{{ this?.errors?.last_login_ip }}</div>
            </CCol>
        </CRow>
        <CRow class="justify-content mb-4">
            <CCol :xs="12" :md="12" :lg="12">
                <CFormLabel>{{ $t('timezone') }}</CFormLabel>
                <CFormInput :disabled="this.disabledAttribute" :maxlength="10" :placeholder="$t('timezone')" :aria-label="$t('timezone')"
                    :class="{ 'is-invalid': this?.errors?.timezone }" :aria-describedby="$t('timezone')" v-model="formData.timezone" />
                <div class="invalid-feedback">{{ this?.errors?.timezone }}</div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ValidationErrorMixin from '@/mixins/ValidationErrorMixin';
    import ValidationSuccessMixin from '@/mixins/ValidationSuccessMixin';
    import FormSystemsMixin from '@/mixins/FormSystemsMixin';
    // import ValidationSchema from '@/schemas/AddressJPSchema';

    export default {
        name: 'FormUserSettings',
        components: {},
        mixins: [ValidationErrorMixin, ValidationSuccessMixin, FormSystemsMixin],
        data() {
            return {
                dataURL: 'api/v1/user-settings',
                formData: {
                    id: '',
                },
                errors: {
                    id: null,
                },
                endpointOverride: true
            }
        },
        computed: {},
        methods: {
            resolveSubmission: function () {
                this.resetErrors();
                this.refIndexKey++;
                this.boot();
            }
        },
        i18n: {
            messages: {
                en: {
                    id: 'ID',
                    user_name: 'User Name',
                    user_type: 'User Type',
                    organization_name: 'Organization Name',
                    domain_name: 'Domain Name',
                    email_verified_at: 'Email Verified At',
                    last_login: 'Last Login',
                    last_login_ip: 'Last Login IP',
                    timezone: 'Timezone',
                },
                np: {
                    id: 'आईडी',
                    user_name: 'प्रयोगकर्ता नाम',
                    user_type: 'प्रयोगकर्ता प्रकार',
                    organization_name: 'संस्थानको नाम',
                    domain_name: 'डोमेन नाम',
                    email_verified_at: 'ईमेल प्रमाणित गरियो',
                    last_login: 'अन्तिम लगइन',
                    last_login_ip: 'अन्तिम लगइन आईपी',
                    timezone: 'समय क्षेत्र',
                },
                jp: {
                    id: 'ID',
                    user_name: 'ユーザー名',
                    user_type: 'ユーザータイプ',
                    organization_name: '組織名',
                    domain_name: 'ドメイン名',
                    email_verified_at: 'メール確認済み',
                    last_login: '最終ログイン',
                    last_login_ip: '最終ログインIP',
                    timezone: 'タイムゾーン',
                },
            }
        },
    }
</script>
